import {
  SCREEN_MODE_NAMES,
  XL_WIDTH,
  createResizeModeHandler,
} from '../../js/screen_utils';

import events from '../events/events';

if(window.innerWidth < XL_WIDTH) {
  localStorage.setItem('sidebarState', 'false');
}

const sidebarState = localStorage.getItem('sidebarState');

document.addEventListener('DOMContentLoaded', function () {
  const mainBodyWrapper = document.querySelector('#main-body-wrapper');
  const sidebar = document.querySelector('[data-sidebar]');
  const toggleColorBtnText = document.querySelector('.toggle-color-btn__text');
  const headerLogoFull = document.querySelector('#main-header-logo-full');
  const headerLogoShort = document.querySelector('#main-header-logo-short');

  if (sidebarState === 'true') {
    sidebar?.classList?.add('root-sidebar_opened');
    sidebar?.classList?.remove('sidebar-hidden');
    toggleColorBtnText?.classList?.add('hidden');
    headerLogoFull?.classList?.remove('hide-logo');
    headerLogoShort?.classList?.add('hide-logo');
    mainBodyWrapper?.classList?.add('visible');
  } else {
    sidebar?.classList?.remove('root-sidebar_opened');
    toggleColorBtnText?.classList?.remove('hidden');
    headerLogoFull?.classList?.add('hide-logo');
    headerLogoShort?.classList?.remove('hide-logo');
    mainBodyWrapper?.classList?.add('visible');
  }
});


function toggleSidebar(event) {

  event.preventDefault();
  const sidebar = document.querySelector('[data-sidebar]');
  const toggleColorBtnText = document.querySelector('.toggle-color-btn__text');
  const headerLogoFull = document.querySelector('#main-header-logo-full');
  const headerLogoShort = document.querySelector('#main-header-logo-short');
  const body = document.querySelector('body');

  document.querySelectorAll('[data-toggle-sidebar]').forEach((el) => {
    el.classList.toggle('nav-btn_opened');
  });

  if (window.innerWidth < 1440) {
    if (sidebar.classList.contains('root-sidebar_opened')) {
      body.style.overflow = 'auto';
      sidebar.style.overflow = 'visible'
    } else {
      body.style.overflow = 'hidden';
      sidebar.style.overflow = 'auto'
    }
  }

  if (toggleColorBtnText) {
    if (!sidebar.classList.contains('root-sidebar_opened')) {
      toggleColorBtnText.classList.add('hidden');
      headerLogoFull.classList.remove('hide-logo');
      headerLogoShort.classList.add('hide-logo');
    } else {
      toggleColorBtnText.classList.remove('hidden');
      headerLogoFull.classList.add('hide-logo');
      headerLogoShort.classList.remove('hide-logo');
    }
  }

  const offset = document.querySelector('.root-header').clientHeight;
  sidebar.style.top = `${offset}px`;
  sidebar.classList.toggle('root-sidebar_opened');

  if (window.innerWidth > XL_WIDTH) {
    localStorage.setItem('sidebarState', sidebar.classList.contains('root-sidebar_opened'));
    if (
      !$.cookie('toggle_sidebar') ||
      $.cookie('toggle_sidebar') == 'null' ||
      $.cookie('toggle_sidebar') == 'False'
    ) {
      document.cookie = 'toggle_sidebar=True; path=/; SameSite=Lax;';
      sidebar.classList.add('root-sidebar_opened');
    } else {
      document.cookie = 'toggle_sidebar=False; path=/; SameSite=Lax;';
      sidebar.classList.remove('root-sidebar_opened');
      setTimeout(function () {
        // если сайдбар открылся при загрузке страницы то у сладйдера инитится размер экрана меньше чем при скрытом сайдбаре
        // поэтому при закрытии сайдбара необходимо обновить слайдер, для корректного отображения
        $('.banners .carousel-inner').slick('refresh');
      }, 600);
    }
  }
}

events.on('breakpoint.change', (current) => {
  if (current.name === 'sm') {
    document.querySelector('body').classList.remove('scroll-lock');
  }
  document.querySelectorAll('[data-toggle-sidebar]').forEach((el) => {
    el.classList.remove('nav-btn_opened');
  });
  document
    .querySelector('[data-sidebar]')
    .classList.remove('root-sidebar_opened');
});

function setMobileSideBar() {
  $('.page-leftbar').hide();
  $('#page-content').css({ marginLeft: 0 });
  $('.icon-open-nested-nav').removeClass('hidden');
  $('[data-nav-href]:not(.icon-open-nested-nav-wrapper)').each(function () {
    $(this)
      .addClass('icon-open-nested-nav-wrapper')
      .append(
        $(`
                <span class="icon-open-nested-nav">
                    <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 7.75L4.25 4L0.5 0.249998L0.5 7.75Z" fill="#676767" />
                    </svg>
                </span>
            `)
      );
  });
  if (
    document.location.pathname.startsWith('/catalog/') &&
    !$('.nested-nav-item__categories .search-extend').length
  ) {
    const $extendSearchForm = $('#id_search-extend-form').clone();
    const $item = $(`<li></li>`);
    $item.append($extendSearchForm);
    $('.nested-nav-item__categories ul.acc-menu').append($item);
    $('.nested-nav-item__categories').removeClass('hidden');
    $('.sidebar-nav__title.navigation-title').html(getTitleCatalog());
  } else if (!$('.nested-nav-item__acc_menu ul.acc-menu').length) {
    const $sidebar = $('.page-leftbar ul.acc-menu').clone();
    if (!$sidebar.children().length) {
      return;
    }
    $sidebar.removeAttr('id');
    const $menuItem = $('.nested-nav-item__acc_menu');
    $menuItem.append($sidebar);
    $menuItem.removeClass('hidden');
    $('.sidebar-nav__title.navigation-title').html(getTitleAccMenu());
  }
}

function setDesktopSideBar() {
  $('.page-leftbar').show();
  // $('#page-content').css({ marginLeft: 255 });
  $('.icon-open-nested-nav').addClass('hidden');
  $('.sidebar-nav__title.navigation-title').text(gettext('Навигация'));
  $('.root-nav').removeClass('hidden');
  $('.nested-nav').addClass('hidden');
  $('.nested-nav-item').addClass('hidden');
}

function getTitleCatalog() {
  return $(`<a href="/catalog/">${gettext('Каталог')}</a>`);
}

function getTitleAccMenu() {
  if (document.location.pathname.startsWith('/catalog_supplier/')) {
    return $(`<a href="/catalog_supplier/">${gettext('Мой каталог')}</a>`);
  } else {
    return document.getElementsByTagName('title')[0].innerText;
  }
}

function stickyBottomInfoButton() {
  const $aboveFooter = $('.info-button-item.above_footer');
  const footer = $('.root-footer');
  if ($aboveFooter.length) {
    const paddingBottom = 15;
    const itemHeight = $aboveFooter.height() + paddingBottom;
    let innerHeight = window.innerHeight - itemHeight;
    const footerHeight = footer.height();
    let initBottom = innerHeight;
    if (window.scrollY + innerHeight > footer[0].offsetTop + footerHeight) {
      initBottom -= footerHeight;
    }
    $aboveFooter.css({
      position: 'absolute',
      width: '100%',
      top: initBottom + 'px',
      zIndex: 99,
      bottom: 0,
    });
    $(window).scroll(function () {
      let innerHeight = window.innerHeight - itemHeight;
      let position = innerHeight + window.scrollY - itemHeight;
      if (window.innerHeight + window.scrollY > footer[0].offsetTop) {
        position -= window.innerHeight + window.scrollY - footer[0].offsetTop;
      }
      $aboveFooter.css({
        top: position + 'px',
      });

      // при скроле окно со страницами должно двигаться за кнопкой
      let sidebarInfoPages = $('.sidebar-info-pages');
      let sidebarInfoPagesPosition =
        position + itemHeight + 45 - Math.floor(sidebarInfoPages.height());
      sidebarInfoPages.css({
        top: sidebarInfoPagesPosition + 'px',
      });
    });

    $('.info-button[data-toggle="popover"]').on(
      'shown.bs.popover',
      function () {
        // если добавлено более 2 страниц для показа, и кнопка прилеплена внизу, то все страницы после 2 скрыты за экраном,
        // поэтому необходимо полностью поднять окно, и опустить вниз на 45px, т.к. размер 1 строчки является 70px,
        // разница в 25px будет отсутопом от нижней границы
        // расчитываю высоту для отступа так же, как и в методе выше при скроле
        let sidebarInfoPages = $('.sidebar-info-pages');
        let innerHeight =
          window.innerHeight + 45 - Math.floor(sidebarInfoPages.height());
        let position = innerHeight + window.scrollY - itemHeight;
        if (window.innerHeight + window.scrollY > footer[0].offsetTop) {
          position -= window.innerHeight + window.scrollY - footer[0].offsetTop;
        }
        sidebarInfoPages.css({
          top: position + 'px',
          left: ($.cookie('toggle_sidebar') == 'True' ? 245 : 55) + 'px',
          transform: 'translate3d(0px, 0px, 0px)',
        });
      }
    );
  }
  $('.info-button[data-toggle="popover"]').on('shown.bs.popover', function () {
    $('.sidebar-info-pages').css({
      left: ($.cookie('toggle_sidebar') == 'True' ? 245 : 55) + 'px',
      transform: 'translate3d(0px, 0px, 0px)',
    });
  });
}

function initSidebarToggle() {
  document.querySelectorAll('[data-click-sidebar]').forEach((el) => {
    el.removeEventListener('click', toggleSidebar);
  });
  document.querySelectorAll('[data-click-sidebar]').forEach((el) => {
    el.addEventListener('click', toggleSidebar);
  });
}

function hideNestedNav() {
  $('.sidebar-nav__title.navigation-title').text(gettext('Навигация'));
  $('.root-nav').removeClass('hidden');
  $('.nested-nav').addClass('hidden');
  $('.nested-nav-item').addClass('hidden');
}
// Решает проблему с вложенным списком когда когда быстро происходит открытие-закрытие сайдбара
// и элемент collapse  остается открытым при закрытом сайдбаре
let collapseTimeout;
let isOrderCollapseVisible = false;
function toggleRequestSidebarCollapse(show = false) {
  const $orderCollapse = $('#order-collapse');
  if (!$orderCollapse.length) return;
  clearTimeout(collapseTimeout);

  if (show && !isOrderCollapseVisible) {
    collapseTimeout = setTimeout(() => {
      $orderCollapse.stop(true, true).collapse('show');
      isOrderCollapseVisible = true;
    }, 100);
  } else if (!show && isOrderCollapseVisible) {
    collapseTimeout = setTimeout(() => {
      $orderCollapse.stop(true, true).collapse('hide');
      isOrderCollapseVisible = false;
    }, 100);
  }
}

export default function initSidebar() {
  initSidebarToggle();
  const dropDownButtonOpen = document.querySelector('#orderRequestCollapse');
  const dropDownActiveButton = document.querySelector('.sidebar-nav__subitem active');

  function closeSelect2Dropdown() {
    // Используется только при открытии сайдбара. Закрывает все выпадающие
    // списки на странице, т.к. они имеют абсолютное позиционирование
    $('.select2-dropdown').parent().remove();
    $('.select2-container--open').removeClass('select2-container--open');
    let select2Selection = $('.select2-selectionr');
    select2Selection.attr('aria-haspopup', false);
    select2Selection.removeAttr('aria-owns');
  }

  let bodySidebar = $('.body__sidebar');
  const arrow = document.querySelector('.main-header__nav .bi-svg-menu')
  bodySidebar.on('mouseenter', function () {
    $('.sidebar-info-pages').css({ left: 245 + 'px' });
    arrow.classList.remove('bi-svg-menu-rotate')
    closeSelect2Dropdown();
    if(localStorage.getItem('sidebarState') === 'false') {
      dropDownButtonOpen?.classList?.remove('displayNone');
      dropDownActiveButton?.classList?.add('active');
    }
  });
  bodySidebar.on('mouseleave', function () {
    if(localStorage.getItem('sidebarState') === 'false') {
      dropDownActiveButton?.classList?.remove('active');
      dropDownButtonOpen?.classList?.add('displayNone');
    }
    if ($.cookie('toggle_sidebar') == 'False') {
      $('.sidebar-info-pages').css({ left: 55 + 'px' });
    }
    if (localStorage.getItem('sidebarState') === 'false') {
      toggleRequestSidebarCollapse(false);
    }
    arrow.classList.add('bi-svg-menu-rotate')
    $('.banners .carousel-inner').slick('refresh');
  });

  const resizeCallback = createResizeModeHandler({
    [SCREEN_MODE_NAMES.XL]: setMobileSideBar,
    [SCREEN_MODE_NAMES.XXL]: setDesktopSideBar,
  });

  window.addEventListener('resize', (e) => {
    resizeCallback(e.target.innerWidth);
    window.dispatchEvent(new Event('scroll'));
  });

  setTimeout(() => window.dispatchEvent(new Event('resize')), 500);

  const navHandlers = {
    '#categories': {
      getTitle: getTitleCatalog,
      handler: () => {
        $('.nested-nav-item__categories').removeClass('hidden');
        return true;
      },
    },
    '#open_acc_menu': {
      getTitle: getTitleAccMenu,
      handler: (href) => {
        if (!document.location.pathname.startsWith(href)) {
          return false;
        }
        $('.nested-nav-item__acc_menu').removeClass('hidden');
        return true;
      },
    },
  };

  $('.nav-back-btn').on('click', hideNestedNav);

  $('[data-nav-href]').on('click', function (e) {
    e.preventDefault();
    const href = $(this).data('navHref');
    const navConf = navHandlers[href];
    if (navConf && window.innerWidth < XL_WIDTH) {
      $('.nested-nav-item').addClass('hidden');
      const isOpen = navConf.handler($(this).attr('href'));
      if (isOpen) {
        $('.sidebar-nav__title.navigation-title').html(navConf.getTitle());
        $('.root-nav').addClass('hidden');
        $('.nested-nav').removeClass('hidden');
        return;
      }
    }
    if (!$(this).hasClass('vue-route-handle')) {
      document.location.href = $(this).attr('href');
    }
  });
  stickyBottomInfoButton();
}

events.on('main.inited', () => {
  initSidebar();
  $(function () {
    const overlay = $('#main-overlay');
    $('.info-button[data-toggle="popover"]').on('show.bs.popover', function () {
      overlay.addClass('show');
    });
    $('.info-button[data-toggle="popover"]').on('hide.bs.popover', function () {
      overlay.removeClass('show');
    });
  });
});

/**
 * Иногда bootstrap не до конца закрывает свою модалку. Помогаем ему.
 */
export function fixBootstrapModals() {
  $('.modal').on('hide.bs.modal', function (e) {
    if ($('.modal.fade.in').length === 1) {
      $(document.body).removeClass('modal-open');
      $('.modal-backdrop').remove();
    }
  });
}

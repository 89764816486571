import {
  SCREEN_MODE_NAMES,
  XL_WIDTH,
  createResizeModeHandler,
} from '../../js/screen_utils';

import events from '../events/events';

// if(window.innerWidth < XL_WIDTH) {
//   localStorage.setItem('sidebarState', 'false');
// }

const sidebarState = localStorage.getItem('sidebarState');

document.addEventListener('DOMContentLoaded', function () {

  const mainBodyWrapper = document.querySelector('#main-body-wrapper');
  const sidebar = document.querySelector('[data-sidebar]');
  const toggleColorBtnText = document.querySelector('.toggle-color-btn__text');
  const headerLogoFull = document.querySelector('#main-header-logo-full');
  const headerLogoShort = document.querySelector('#main-header-logo-short');
  const toggleButton = document.querySelector('.turn');
  const className = 'open-sidebar';
  const arrowIcon = document.querySelector('.arrow-icon');
  const rootContent = document.querySelector('#root-content');
  const toggleSidebarButton = document.querySelector('#toggle-sidebar-button');

  if (localStorage.getItem('sidebarState') === 'true') {
    sidebar.classList.add(className);
    toggleSidebarButton.textContent = 'Скрыть'
    arrowIcon.classList.add('transform-arrow');
  }else{
    toggleSidebarButton.textContent = 'Закрепить'
    arrowIcon.classList.remove('transform-arrow');
  }

  toggleButton.addEventListener('click', function () {
    sidebar.classList.toggle(className);

    const isActive = sidebar.classList.contains(className);
    localStorage.setItem('sidebarState', JSON.stringify(isActive));
    if (isActive) {
      toggleSidebarButton.textContent = 'Скрыть';
      arrowIcon.classList.add('transform-arrow');
    } else {
      toggleSidebarButton.textContent = 'Закрепить';
      arrowIcon.classList.remove('transform-arrow');
    }

  });

  if (sidebarState === 'true') {
    headerLogoFull?.classList?.remove('hide-logo');
    headerLogoShort?.classList?.add('hide-logo');
    mainBodyWrapper?.classList?.add('visible');
  } else {
    headerLogoFull?.classList?.add('hide-logo');
    headerLogoShort?.classList?.remove('hide-logo');
    mainBodyWrapper?.classList?.add('visible');
  }



});

//
// function toggleSidebar(event) {
//   event.preventDefault();
//   const sidebar = document.querySelector('[data-sidebar]');
//   const toggleColorBtnText = document.querySelector('.toggle-color-btn__text');
//   const rootContent = document.querySelector('#root-content');
//   const body = document.querySelector('body');
//
//   const openIcon = document.querySelector('nav-btn__is-opened');
//   const closeIcon = document.querySelector('nav-btn__is-closed');
//
//   document.querySelectorAll('[data-toggle-sidebar]').forEach((el) => {
//     el.classList.toggle('nav-btn_opened');
//   });
//
//   if (toggleColorBtnText) {
//     if (isSidebarOpened) {
//       rootContent.classList.add('add-blur');
//       body.classList.add('remove-scroll');
//       openIcon.classList.add('d-none');
//       closeIcon.classList.remove('d-none');
//     } else {
//       rootContent.classList.remove('add-blur');
//       body.classList.remove('remove-scroll');
//       openIcon.classList.remove('d-none');
//       closeIcon.classList.add('d-none');
//     }
//   }
//
// }

function setMobileSideBar() {
  $('.page-leftbar').hide();
  $('#page-content').css({ marginLeft: 0 });
  $('.icon-open-nested-nav').removeClass('hidden');
  $('[data-nav-href]:not(.icon-open-nested-nav-wrapper)').each(function () {
    $(this)
      .addClass('icon-open-nested-nav-wrapper')
      .append(
        $(`
                <span class="icon-open-nested-nav">
                    <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 7.75L4.25 4L0.5 0.249998L0.5 7.75Z" fill="#676767" />
                    </svg>
                </span>
            `)
      );
  });
  if (
    document.location.pathname.startsWith('/catalog/') &&
    !$('.nested-nav-item__categories .search-extend').length
  ) {
    const $extendSearchForm = $('#id_search-extend-form').clone();
    const $item = $(`<li></li>`);
    $item.append($extendSearchForm);
    $('.nested-nav-item__categories ul.acc-menu').append($item);
    $('.nested-nav-item__categories').removeClass('hidden');
    $('.sidebar-nav__title.navigation-title').html(getTitleCatalog());
  } else if (!$('.nested-nav-item__acc_menu ul.acc-menu').length) {
    const $sidebar = $('.page-leftbar ul.acc-menu').clone();
    if (!$sidebar.children().length) {
      return;
    }
    $sidebar.removeAttr('id');
    const $menuItem = $('.nested-nav-item__acc_menu');
    $menuItem.append($sidebar);
    $menuItem.removeClass('hidden');
    $('.sidebar-nav__title.navigation-title').html(getTitleAccMenu());
  }
}

function setDesktopSideBar() {
  $('.page-leftbar').show();
  // $('#page-content').css({ marginLeft: 255 });
  $('.icon-open-nested-nav').addClass('hidden');
  $('.sidebar-nav__title.navigation-title').text(gettext('Навигация'));
  $('.root-nav').removeClass('hidden');
  $('.nested-nav').addClass('hidden');
  $('.nested-nav-item').addClass('hidden');
}

function getTitleCatalog() {
  return $(`<a href="/catalog/">${gettext('Каталог')}</a>`);
}

function getTitleAccMenu() {
  if (document.location.pathname.startsWith('/catalog_supplier/')) {
    return $(`<a href="/catalog_supplier/">${gettext('Мой каталог')}</a>`);
  } else {
    return document.getElementsByTagName('title')[0].innerText;
  }
}
//
// function stickyBottomInfoButton() {
//   const $aboveFooter = $('.info-button-item.above_footer');
//   const footer = $('.root-footer');
//   if ($aboveFooter.length) {
//     const paddingBottom = 15;
//     const itemHeight = $aboveFooter.height() + paddingBottom;
//     let innerHeight = window.innerHeight - itemHeight;
//     const footerHeight = footer.height();
//     let initBottom = innerHeight;
//     if (window.scrollY + innerHeight > footer[0].offsetTop + footerHeight) {
//       initBottom -= footerHeight;
//     }
//     $aboveFooter.css({
//       position: 'absolute',
//       width: '100%',
//       top: initBottom + 'px',
//       zIndex: 99,
//       bottom: 0,
//     });
//     $(window).scroll(function () {
//       let innerHeight = window.innerHeight - itemHeight;
//       let position = innerHeight + window.scrollY - itemHeight;
//       if (window.innerHeight + window.scrollY > footer[0].offsetTop) {
//         position -= window.innerHeight + window.scrollY - footer[0].offsetTop;
//       }
//       $aboveFooter.css({
//         top: position + 'px',
//       });
//
//       // при скроле окно со страницами должно двигаться за кнопкой
//       let sidebarInfoPages = $('.sidebar-info-pages');
//       let sidebarInfoPagesPosition =
//         position + itemHeight + 45 - Math.floor(sidebarInfoPages.height());
//       sidebarInfoPages.css({
//         top: sidebarInfoPagesPosition + 'px',
//       });
//     });
//
//     $('.info-button[data-toggle="popover"]').on(
//       'shown.bs.popover',
//       function () {
//         // если добавлено более 2 страниц для показа, и кнопка прилеплена внизу, то все страницы после 2 скрыты за экраном,
//         // поэтому необходимо полностью поднять окно, и опустить вниз на 45px, т.к. размер 1 строчки является 70px,
//         // разница в 25px будет отсутопом от нижней границы
//         // расчитываю высоту для отступа так же, как и в методе выше при скроле
//         let sidebarInfoPages = $('.sidebar-info-pages');
//         let innerHeight =
//           window.innerHeight + 45 - Math.floor(sidebarInfoPages.height());
//         let position = innerHeight + window.scrollY - itemHeight;
//         if (window.innerHeight + window.scrollY > footer[0].offsetTop) {
//           position -= window.innerHeight + window.scrollY - footer[0].offsetTop;
//         }
//         sidebarInfoPages.css({
//           top: position + 'px',
//           left: ($.cookie('toggle_sidebar') == 'True' ? 245 : 55) + 'px',
//           transform: 'translate3d(0px, 0px, 0px)',
//         });
//       }
//     );
//   }
//   $('.info-button[data-toggle="popover"]').on('shown.bs.popover', function () {
//     $('.sidebar-info-pages').css({
//       left: ($.cookie('toggle_sidebar') == 'True' ? 245 : 55) + 'px',
//       transform: 'translate3d(0px, 0px, 0px)',
//     });
//   });
// }
//
// function initSidebarToggle() {
//   document.querySelectorAll('[data-click-sidebar]').forEach((el) => {
//     el.removeEventListener('click', toggleSidebar);
//   });
//   document.querySelectorAll('[data-click-sidebar]').forEach((el) => {
//     el.addEventListener('click', toggleSidebar);
//   });
// }

function hideNestedNav() {
  $('.sidebar-nav__title.navigation-title').text(gettext('Навигация'));
  $('.root-nav').removeClass('hidden');
  $('.nested-nav').addClass('hidden');
  $('.nested-nav-item').addClass('hidden');
}
//
// export default function initSidebar() {
//   initSidebarToggle();
//
//   const resizeCallback = createResizeModeHandler({
//     [SCREEN_MODE_NAMES.XL]: setMobileSideBar,
//     [SCREEN_MODE_NAMES.XXL]: setDesktopSideBar,
//   });
//
//   window.addEventListener('resize', (e) => {
//     resizeCallback(e.target.innerWidth);
//     window.dispatchEvent(new Event('scroll'));
//   });
//
//   setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
//
//   const navHandlers = {
//     '#categories': {
//       getTitle: getTitleCatalog,
//       handler: () => {
//         $('.nested-nav-item__categories').removeClass('hidden');
//         return true;
//       },
//     },
//     '#open_acc_menu': {
//       getTitle: getTitleAccMenu,
//       handler: (href) => {
//         if (!document.location.pathname.startsWith(href)) {
//           return false;
//         }
//         $('.nested-nav-item__acc_menu').removeClass('hidden');
//         return true;
//       },
//     },
//   };
//
//   $('.nav-back-btn').on('click', hideNestedNav);
//
//   $('[data-nav-href]').on('click', function (e) {
//     e.preventDefault();
//     const href = $(this).data('navHref');
//     const navConf = navHandlers[href];
//     if (navConf && window.innerWidth < XL_WIDTH) {
//       $('.nested-nav-item').addClass('hidden');
//       const isOpen = navConf.handler($(this).attr('href'));
//       if (isOpen) {
//         $('.sidebar-nav__title.navigation-title').html(navConf.getTitle());
//         $('.root-nav').addClass('hidden');
//         $('.nested-nav').removeClass('hidden');
//         return;
//       }
//     }
//     if (!$(this).hasClass('vue-route-handle')) {
//       document.location.href = $(this).attr('href');
//     }
//   });
//   // stickyBottomInfoButton();
// }
//
// events.on('main.inited', () => {
//   initSidebar();
//   $(function () {
//     const overlay = $('#main-overlay');
//     $('.info-button[data-toggle="popover"]').on('show.bs.popover', function () {
//       overlay.addClass('show');
//     });
//     $('.info-button[data-toggle="popover"]').on('hide.bs.popover', function () {
//       overlay.removeClass('show');
//     });
//   });
// });

document.addEventListener('click', function (event) {
  const sidebar = document.querySelector('[data-sidebar]');
  const openIcon = document.querySelector('.nav-btn__is-closed');
  const closeIcon = document.querySelector('.nav-btn__is-opened-icon');
  const rootContent = document.querySelector('#root-content');
  const body = document.querySelector('body');

  const isClickInsideSidebar = sidebar.contains(event.target);



  if (openIcon && event.target === openIcon) {
    openIcon.classList.add('d-none');
    closeIcon.classList.remove('d-none');
    rootContent.classList.add('blur');
    body.classList.add('remove-scroll');
    sidebar.classList.add('sidebar--opened');
    sidebar.style.maxWidth = '265px';
    sidebar.style.setProperty('display', 'block', 'important');
    return;
  }


  if (closeIcon && event.target === closeIcon) {
    closeIcon.classList.add('d-none');
    openIcon.classList.remove('d-none');
    rootContent.classList.remove('blur');
    body.classList.remove('remove-scroll');
    sidebar.classList.remove('sidebar--opened');
    sidebar.style.removeProperty('max-width');
    sidebar.style.removeProperty('display');
    return;
  }

  if (!isClickInsideSidebar) {
    closeIcon.classList.add('d-none');
    openIcon.classList.remove('d-none');
    rootContent.classList.remove('blur');
    body.classList.remove('remove-scroll');
    sidebar.classList.remove('sidebar--opened');
    sidebar.style.removeProperty('max-width');
    sidebar.style.removeProperty('display');
  }


});



import './main.scss';

import svg4everybody from 'svg4everybody';
import events from './components/events/events';
import './components/sidebar/sidebar';
import './components/dropdown/dropdown';
import './components/header/header';
import './components/breakpoint/breakpoint';
import './components/locale/locale';
import './components/product/image';
import { initAutocompleteSearch } from './js/autocomplete-search';
import Cookies from 'js-cookie';
import { initCustomNumberInputs } from './js/products-count';
import { fixBootstrapModals } from './js/modal-fix';

var gettext = global.django?.gettext || ((msg) => msg);
global.gettext = gettext;

document.addEventListener('DOMContentLoaded', function () {
  svg4everybody();

  const sidebar = document.getElementById('sidebar');
  if (sidebar && sidebar.innerHTML.trim() !== '') {
    document.body.classList.remove('collapse-leftbar');
  }

  $(document).ajaxError(function (event, xhr) {
    if (xhr.status === 401) {
      if (xhr.responseJSON?.login_url) {
        window.location.assign(xhr.responseJSON.login_url);
      }
    }
  });

  events.emit('main.inited');
  // Не всегда при изменении языка подставляется правильная кука, подставим ее сами
  $('.language_dropdown__btn').on('click', function (event) {
    event.preventDefault();
    const $form = $(event.target.closest('form'));
    Cookies.set(
      'csrftoken',
      $form.find('input[name=csrfmiddlewaretoken]').val()
    );
    $form.trigger('submit');
  });

  $('.autocomplete-container').each(function () {
    initAutocompleteSearch(
      $(this),
      '.autocomplete-input',
      '.autocomplete-dropdown'
    );
  });

  // инициализируем кастомные инпуты со стрелками
  initCustomNumberInputs();

  fixBootstrapModals();

  // bootstrap tooltip everywhere
  $('[data-toggle="tooltip-white"]').tooltip({
    html: true,
    template:
      '<div class="tooltip tooltip-white" role="tooltip"><div class="tooltip-inner"></div></div>',
  });

  $('[data-toggle="tooltip-black"]').tooltip({
    html: true,
    template:
      '<div class="tooltip tooltip-black" role="tooltip"><div class="tooltip-inner"></div></div>',
  });

  $('.alert').each((i, el) => {
    if (!$(el).hasClass('static-alert')) {
      $(el).addClass('floating-alert');
      document.body.appendChild(el);
    }
  });
  $('#items_on_page').on('change',  function(event) {
    const selectedValue = event.target.value;
    const params = new URLSearchParams(window.location.search);
    params.set('items_on_page', selectedValue);
    if (params.has('page')) {
      params.set('page', '1');
    }
    const url = window.location.pathname + '?' + params.toString();
    window.location.href = url;
  });
});

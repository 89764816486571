import '@fancyapps/ui/dist/fancybox.css';
import { Fancybox } from '@fancyapps/ui';
import Swiper from 'swiper/bundle';
import 'swiper/css';
import Drift from 'drift-zoom';
import events from '../events/events';

document.addEventListener('DOMContentLoaded', () => {
  // Инициализация слайдера
  const productImagesEl = document.getElementById('productImages');
  const productImagesThumbsEl = document.getElementById('productImagesThumbs');
  if (!productImagesEl || !productImagesThumbsEl) return;

  const productImagesThumbsSwiper = new Swiper(productImagesThumbsEl, {
    spaceBetween: 10,
    slidesPerView: 'auto',
    freeMode: true,
    watchSlidesProgress: true,
  });

  new Swiper(productImagesEl, {
    spaceBetween: 10,
    thumbs: {
      swiper: productImagesThumbsSwiper,
    },
  });
});

document.addEventListener('DOMContentLoaded', () => {
  /**
   * Инициализация зума
   * https://github.com/imgix/drift
   *
   * На мобилках зум не помещается, поэтому его надо скрывать.
   * У Drift есть опция handleTouch: false, чтобы отключить срабатывание по нажатию,
   * но тогда не работает скролл на мобилках, если тянить за изображение.
   * По примеру из документации собираем экземпляры Drift,
   * и на мобилках их уничтожаем, а на десктопе инициализируем,
   * проверяем при window.resize.
   * */
  const productImagesEl = document.getElementById('productImages');
  if (!productImagesEl) return;
  const breakpoint = 992;
  const zooms = [];
  window.zooms = zooms;

  const initZoom = () => {
    /** Инициализация зума на всех картинках */
    for (let el of productImagesEl
      .closest('.zoom-wrapper')
      .querySelectorAll('.swiper-wrapper img')) {
      const drift = new Drift(el, {
        paneContainer: el.closest('.zoom-wrapper').querySelector('.zoom'),
        inlinePane: false,
        zoomFactor: 2,
        hoverBoundingBox: true,
        showWhitespaceAtEdges: true,
      });
      zooms.push(drift);
    }
  };

  const destroyZoom = () => {
    /** Уничтожение зума на всех картинках */
    while (zooms.length) {
      zooms.pop().destroy();
    }
  };

  const handleChange = () => {
    if (window.innerWidth < breakpoint && !!zooms.length) {
      destroyZoom();
    } else if (window.innerWidth >= breakpoint && !zooms.length) {
      initZoom();
    }
  };
  events.on('breakpoint.change', handleChange);
  handleChange();
});

import events from '../events/events';
import Overlay from '../overlay/overlay';
import breakpoint from '../breakpoint/breakpoint';

let overlay;
events.on('main.inited', () => {
  const parent = document.getElementById('body-content');
  overlay = new Overlay(parent, 'overlay_body-content');


  $(document).click(function (event) {
    if (!$(event.target).closest('.header-search__dropdown').length) {
      if ($('.header-search__dropdown.show').length) {
        $('.header-search__dropdown').removeClass('show');
      }
    }
  });

  function changeLocationQuery(newQueryObj) {
    const query = {};
    const queryList = window.location.search
      .slice(1)
      .split('&')
      .filter(Boolean)
      .map((x) => x.split('='));
    queryList.forEach(([k, v]) => (query[k] = v));
    const newQuery = Object.assign(query, newQueryObj);
    const url =
      window.location.pathname +
      Object.entries(newQuery)
        .filter(([k, v]) => !!v)
        .reduce((acc, [k, v]) => acc + `${k}=${encodeURIComponent(v)}&`, '?')
        .slice(0, -1);
    window.history.replaceState({}, '', url);
  }

  function highlightItem(item, query) {
    const html = $('<div></div>');
    let leftPart, middlePart, rightPart, strong;
    let query_splitted = query.split(' ');
    for (const query_part of query_splitted) {
      let query_len = query_part.length;
      if (query_len === 0) {
        continue;
      }
      let i = item.toLowerCase().indexOf(query_part.toLowerCase());
      while (i > -1) {
        leftPart = item.substr(0, i);
        middlePart = item.substr(i, query_len);
        rightPart = item.substr(i + query_len);
        strong = $('<strong> </strong>').text(middlePart);
        html.append(document.createTextNode(leftPart)).append(strong);
        item = rightPart;
        i = item.toLowerCase().indexOf(query_part.toLowerCase());
      }
    }
    return html.append(document.createTextNode(item)).html();
  }

  document.querySelectorAll('.header-search__input').forEach((inputEl) => {
    inputEl.addEventListener('change', function () {
      document
        .querySelectorAll('[data-hidden-search]')
        .forEach((hiddenInput) => {
          hiddenInput.value = this.value;
        });
    });
    const lodash = require('lodash');

    inputEl.addEventListener('keyup', function (event) {
      const $this = $(this);
      const val = $this.val();
      const $icon = $this.closest('.header-search__container').find('.header-search__icon');
      var isSupplier = 0;
      const $dropdown = $this
        .closest('.header-search__container')
        .find('.header-search__dropdown');
      changeLocationQuery({ pk: '', query: val });
      $('#id_pk').val('');
      if (val.length > 2) {
        let inProcess = $icon.hasClass('preloader-active');
        if (inProcess) return;

        let extra = $this.data('extra') || {};
        $icon.addClass('preloader-active');
        if ($this.data("fromSupplierCatalog")) {
          extra["from_supplier_catalog"] = true;
          isSupplier = 1;
        }

        const sendSearchRequest = lodash.debounce(() => {
          var val = $this.val();
          $.get(
            $this.data('url'), { search: val, ...extra }).done((data) => {
            events.emit('onAutoCompeteSearch', data?.results || []);
            if (data?.results?.length) {
              const liItems = data.results.map((item) => {
                let text = '';
                if (item.code) {
                  text = `${item.code} ${item.name}`;
                } else {
                  text = `${item.name}`;
                }
                const highlighted = highlightItem(text, val);
                return `<li data-id="${item.id}">${highlighted}</li>`;
              });
              const $optionsList = $(`<ul>${liItems.join('\n')}</ul>`);
              $optionsList.find('li').on('click', function () {
                const pk = $(this).data('id');
                const search = $(this).text();
                const is_supplier_catalog = $(this).data("is_supplier_catalog")
                changeLocationQuery({ pk, query: search, is_supplier: isSupplier });
                events.emit('onSelectAutoCompleteItem', pk);
                $('#id_pk').val(pk);
                $this.val(search);
                $dropdown.removeClass('show');
                $this.closest('form').submit();
              });
              $dropdown.html($optionsList);

              if (event.keyCode === 13 || event.key === 'Enter') {
                  $dropdown.removeClass('show');
              } else {
                  $dropdown.addClass('show');
              }
            } else {
              $dropdown.removeClass('show');
            }
            $icon.removeClass('preloader-active');
          });
        }, 600);
        sendSearchRequest();
      }
    });
  });
});

events.on('dropdown.toggle', (el, dropdown, isVisible) => {
  if (dropdown === 'header-extended-search') {
    if (isVisible) {
      overlay.show();
      document.body.style = 'overflow-y: hidden;';
    } else {
      overlay.hide();
      document.body.style = null;
    }

    const container = el.closest('.header-search_xl');
    if (container) {
      container.classList.toggle('header-search_wide', isVisible);
    }
    const header = document.querySelector('header.root-header');
    const mainHeader = header.querySelector('.main-header');
    if (isVisible) {
      const filterBlock = document.querySelector('[data-load-filter]');
      header.style.position = 'absolute';
      window.scrollTo(0, 0);
      mainHeader.style.minHeight = '65px';
      if (filterBlock && !filterBlock.dataset.loaded) {
        filterBlock.dataset.loaded = 'true';
        $.get(filterBlock.dataset.url, function (data) {
          $(filterBlock).html(data);
          const event = new Event('onUpdatedPropertyFilter');
          setTimeout(() => window.dispatchEvent(event), 50);
        });
      }
    } else {
      header.style.position = null;
      mainHeader.style.minHeight = null;
    }
  }
});

import events from '../events/events';

export const xs = 375;
export const sm = 800;
export const xl = 1440;
const breakpoints = [
  { name: 'xs', size: xs, media: `(max-width: ${sm - 1}px)` },
  {
    name: 'sm',
    size: sm,
    media: `(min-width: ${sm}px) and (max-width: ${xl - 1}px)`,
  },
  { name: 'xl', size: xl, media: `(min-width: ${xl}px)` },
];

let current = breakpoints[0];

breakpoints.forEach((breakpoint) => {
  const mediaQuery = window.matchMedia(breakpoint.media);
  const emitChange = () => {
    // eslint-disable-next-line no-console
    window.djangoDebug && console.log('breakpoint changed', breakpoint);
    events.emit('breakpoint.change', breakpoint, current);
    current = breakpoint;
  };

  try {
    mediaQuery.addEventListener('change', (event) => {
      event.matches && emitChange();
    });
  } catch (err) {
    // Fallback для Safari <= 12
    mediaQuery.addListener((event) => {
      event.matches && emitChange();
    });
  }
  if (mediaQuery.matches) {
    current = breakpoint;
  }
});

export default current;
